import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { createPath, Link as RouterLink, parsePath } from "react-router-dom";
import { useAssetOutlet } from "~/pages/inventory/asset";
import { AssetConfigurationSection } from "./asset-configuration-section";
import { Space } from "~/lib/types";
import { TestIamActionsQuery } from "~/operations";
import { Loading } from "./loading";
import { ArrowForwardIcon } from "./icons";
import { PolicyCardGrid } from "~/pages/inventory/components/Policies";
import { isFeatureEnabled } from "~/login/features";
import { AssetRiskFactors } from "./vulnerabilities/asset-risk-factors";

type Props = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  isCicd?: boolean;
};

export function OverviewTab({
  availablePermissions,
  space,
  isCicd = false,
}: Props) {
  const { asset, assetPath, hasMondooPlatformVulnPolicy } = useAssetOutlet();

  if (!assetPath) {
    return <Loading what="Overview Tab" />;
  }

  // until backend removes the platform vuln policy from the count, we are managing it here
  let policyCount = asset?.listPolicies?.totalCount || 0;
  // if there is a platform vulnerability policy, remove it from the count
  if (hasMondooPlatformVulnPolicy && policyCount > 0) {
    policyCount--;
  }

  const generateHref = (): string => {
    const parsedPath = parsePath(assetPath);
    const pathname = `${parsedPath.pathname}/policies`;
    const search = parsedPath.search;
    return createPath({ pathname, search });
  };

  return (
    <Box>
      {isFeatureEnabled("Asset Risk Assessment") && (
        <>
          <Grid container sx={{ display: "flex", alignItems: "center", my: 3 }}>
            <Grid item>
              <Typography
                variant="h5"
                component="h3"
                fontWeight={700}
                sx={{ mr: 3 }}
              >
                Risk Assessment
              </Typography>
            </Grid>
            <Grid item container xs alignItems="center">
              <Grid item xs>
                <Divider />
              </Grid>
            </Grid>
          </Grid>

          <AssetRiskFactors />
        </>
      )}

      <Grid container sx={{ display: "flex", alignItems: "center", mt: 3 }}>
        <Grid item>
          <Typography
            variant="h5"
            component="h3"
            fontWeight={700}
            sx={{ mr: 3 }}
          >
            Policies{" "}
            <Typography
              variant="h5"
              component="span"
              fontWeight={700}
              color="text.secondary"
            >
              {policyCount}
            </Typography>
          </Typography>
        </Grid>
        <Grid item container xs alignItems="center">
          <Grid item xs>
            <Divider />
          </Grid>
          {policyCount > 3 && (
            <Grid item>
              <Button
                variant="text"
                sx={{ color: "secondary.light", px: 3 }}
                endIcon={<ArrowForwardIcon />}
                component={RouterLink}
                to={generateHref()}
              >
                View all policies
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* Policy Cards */}
      {asset?.listPolicies && (
        <PolicyCardGrid
          policies={asset?.listPolicies.edges}
          availablePermissions={availablePermissions}
          isCicd={isCicd}
          limit={3}
        />
      )}

      <Grid container sx={{ display: "flex", alignItems: "center", mt: 3 }}>
        <Grid item>
          <Typography
            variant="h5"
            component="h3"
            fontWeight={700}
            sx={{ mr: 3 }}
          >
            Configuration
          </Typography>
        </Grid>
        <Grid item container xs alignItems="center">
          <Grid item xs>
            <Divider />
          </Grid>
          {/* out of scope until configuration tab is designed */}
          {/* <Grid item>
            <Button
              variant="text"
              sx={{ color: "secondary.light", px: 3 }}
              endIcon={<ArrowForwardIcon />}
              component={RouterLink}
              to={generateHref()}
            >
              View Configuration
            </Button>
          </Grid> */}
        </Grid>
      </Grid>

      <AssetConfigurationSection {...{ space, availablePermissions, isCicd }} />
    </Box>
  );
}
